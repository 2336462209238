import { ViewContainerRef, ComponentRef } from '@angular/core';
import { Subject } from 'rxjs';
import { DialogsComponent } from './dialogs.component';

export class CoreDialog {
  private vcr!: ViewContainerRef;
  private componentRef!: ComponentRef<DialogsComponent>;
  private afterClose$ = new Subject<any>();
  private afterEmit$ = new Subject<any>();
  private id!: string;

  constructor(payload: { vcr: ViewContainerRef; id: string }) {
    this.vcr = payload.vcr;
    this.id = payload.id;
  }

  openDialogComponent(component: any, config?: { data: any; classes?: string; width?: string }) {
    this.componentRef = this.vcr.createComponent(DialogsComponent);
    this.componentRef.instance.elementId = this.id;
    this.componentRef.instance.dynamicComponent = component;
    this.componentRef.instance.dynamicComponentInput = { dialogInstance: { dialogRef: this, data: config?.data } };
    this.componentRef.instance.initClasses(config?.classes);
    this.componentRef.instance.initWidth(config?.width);
  }

  async closeDialog(value?: any) {
    await this.componentRef.instance.onClose();
    this.componentRef.destroy();

    this.afterClose$.next(value);
    this.afterClose$.complete();
  }

  emitDialog(value?: any) {
    this.afterEmit$.next(value);
    this.afterEmit$.complete();
  }

  get afterClose() {
    return this.afterClose$;
  }

  get afterEmit() {
    return this.afterEmit$;
  }
}
