import { Injectable, ViewContainerRef } from '@angular/core';
import { CoreDialog } from './dialogs';
import { GenerateRandom } from '../utils/random';

@Injectable({
  providedIn: 'root',
})
export class CoreDialogsService {
  private vcr!: ViewContainerRef;

  classes!: string | undefined;

  constructor() {}

  init(vcr: ViewContainerRef) {
    this.vcr = vcr;
  }

  openDialog(component: any, config?: { data: any; classes?: string; width?: string }) {
    this.classes = config?.classes;

    const id = `modal-${GenerateRandom(6)}`;
    const core = new CoreDialog({ vcr: this.vcr, id });
    core.openDialogComponent(component, config);
    return core;
  }
}
