<div
  [id]="elementId"
  tabindex="-1"
  aria-hidden="true"
  class="fixed alert-backdrop bg-opacity-50 left-0 right-0 top-0 z-50 hidden h-[calc(100%-1rem)] max-h-full w-full overflow-y-auto overflow-x-hidden p-4 md:inset-0"
>
  <div
    [id]="'content-' + elementId"
    class="relative bg-white rounded-lg shadow-lg w-full max-w-md max-h-full transform scale-95 opacity-1 transition-all duration-250 ease-out"
    [style.width]="width"
    [ngClass]="classes"
  >
    <ng-container *ngComponentOutlet="dynamicComponent; inputs: dynamicComponentInput"></ng-container>
  </div>
</div>
