import { CommonModule, NgComponentOutlet } from '@angular/common';
import { AfterViewInit, Component } from '@angular/core';
import { ModalInterface, initFlowbite, ModalOptions, InstanceOptions, Modal } from 'flowbite';

@Component({
  selector: 'app-dialogs',
  standalone: true,
  imports: [NgComponentOutlet, CommonModule],
  templateUrl: './dialogs.component.html',
  styleUrl: './dialogs.component.scss',
})
export class DialogsComponent implements AfterViewInit {
  private modal!: ModalInterface;

  dynamicComponent!: any;
  dynamicComponentInput!: any;
  elementId!: string;
  classes!: string | undefined;
  width!: string | undefined;

  $modalElement!: HTMLElement | null;
  $modalContentElement!: HTMLElement | null;

  ngOnInit(): void {
    initFlowbite();
  }

  ngAfterViewInit(): void {
    this.initDialog();
  }

  initDialog() {
    this.$modalElement = document.querySelector(`#${this.elementId}`);
    this.$modalContentElement = document.querySelector(`#content-${this.elementId}`);

    const modalOptions: ModalOptions = {
      placement: 'center',
      backdrop: 'dynamic',
      backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: false,
      // onHide: () => {
      //   console.log('modal is hidden');
      // },
      // onShow: () => {
      //   console.log('modal is shown');
      // },
      // onToggle: () => {
      //   console.log('modal has been toggled');
      // },
    };

    // instance options object
    const instanceOptions: InstanceOptions = {
      id: this.elementId,
      override: true,
    };

    this.modal = new Modal(this.$modalElement, modalOptions, instanceOptions);

    this.modal.show();
    setTimeout(() => {
      this.$modalContentElement?.classList.remove('opacity-0', 'scale-95');
      this.$modalContentElement?.classList.add('opacity-100', 'scale-100');
    }, 50);
  }

  async onClose() {
    this.$modalContentElement?.classList.remove('opacity-100', 'scale-100');
    this.$modalContentElement?.classList.add('opacity-0', 'scale-95');

    await new Promise((resolve) => setTimeout(resolve, 200));
    this.modal.hide();
  }

  initClasses(classes: string | undefined) {
    this.classes = classes;
  }

  initWidth(width: string | undefined) {
    this.width = width;
  }
}
